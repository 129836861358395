<template>
  <div>
    <button @click="isShow = !isShow">显示/隐藏</button>
    <transition>
      <h1 v-show="isShow" class="come">你好啊！</h1>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    hasShow() {
      let hasShow = this.isShow ? "显示了" : "隐藏了";
      return hasShow;
    },
  },
  watch: {
    hasShow: {
      deep: true,
      handler(oldVal, newVal){
        console.log(oldVal);
      },
    },
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  background-color: orange;
}
/* 动画 */
@keyframes donghua {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0px);
  }
}
.v-enter-active {
  animation: donghua 0.3s linear;
  opacity: 0.5;
}
.v-leave-active {
  animation: donghua 0.3s linear reverse;
  opacity: 0.5;
}
</style>
